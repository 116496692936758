
import { Breadcrumbs } from "@ui";
import * as React from "react";
import { Link, Route, Routes } from "react-router-dom";

export const ROUTE_BASE = "medicine";

const AdminMedicineDash: React.FC<any> = ({ }) => {

  return (
    <div style={{ width: "inherit"}}>
      <Breadcrumbs />
      <Routes>
        <Route path="category_list" element={<span>list of the medicine</span>} />
        <Route
          path="/"
          element={
            <>
              <h2 onClick={() => console.log("clicked")}>user name</h2>

              <h4>Demarrer</h4>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <Link to={`createCategory`}>
                    <i className="fas fa-file" />
                    Nouveau Medicine
                  </Link>
                </li>
                <li>
                  <Link to={`category_list`}>
                    <i className="fas fa-list" aria-hidden="true" />
                    list Medicine...
                  </Link>
                </li>
              </ul>
              <h5>Professionelles</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fas fa-file" />
                  Nouveau Ordonnance Medicine
                </li>
                <li>
                  <i className="fas fa-folder" />
                  Ajouter Extension
                </li>
                <li>
                  <i className="fas fa-folder" />
                  Activer un Compte
                </li>
              </ul>
            </>
          }
        />
      </Routes>
    </div>
  )
}

export default AdminMedicineDash;