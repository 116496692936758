import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import { PUBLIC_READ_MEDICINE_BY_LETTER } from "../controller/medicineController";

const Container = styled("div")`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const AlphabetContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 8px;
`;

const AlphabetButton = styled("button")<{ active: boolean }>`
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: ${({ active }) => (active ? "#007acc" : "#f9f9f9")};
  color: ${({ active }) => (active ? "white" : "#333")};
  cursor: pointer;
  &:hover {
    background-color: #007acc;
    color: white;
  }
`;

const List = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Card = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  min-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Image = styled("div")<{ hasImage: boolean }>`
  width: 50px;
  height: 50px;
  background-color: ${({ hasImage }) => (hasImage ? "#ccc" : "#f9f9f9")};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-right: 15px;
  color: ${({ hasImage }) => (hasImage ? "black" : "#aaa")};
  border: ${({ hasImage }) => (hasImage ? "none" : "1px dashed #aaa")};
`;

const Info = styled("div")`
  flex: 1;
`;

const Title = styled("div")`
  margin: 0;
  font-size: 16px;
  color: #007acc;
  text-decoration: underline;
`;

const Price = styled("div")`
  margin: 5px 0;
  font-size: 14px;
  color: #007acc;
`;

const Status = styled("div")<{ status: string }>`
  font-size: 12px;
  color: ${({ status }) => (status === "Commercialisé" ? "green" : "red")};
`;

const WebMedicineList = () => {
  const { letter } = useParams<{ letter: string }>(); // Fetch the `letter` parameter from the URL

  const { data, loading, error } = useQuery(PUBLIC_READ_MEDICINE_BY_LETTER, {
    variables: { letter },
  });

  const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  return (
    <Container>
      {/* Alphabet Navigation */}
      <AlphabetContainer>
        {alphabets.map((alpha) => (
          <Link to={`../${alpha}`} key={alpha}>
            <AlphabetButton active={letter === alpha}>{alpha}</AlphabetButton>
          </Link>
        ))}
      </AlphabetContainer>

      {/* Loading and Error States */}
      {loading && <div>Loading...</div>}
      {error && <div>Error: Unable to load medicines. Please try again later.</div>}

      {/* Medicines List */}
      <List>
        {data?.public_medicine_read_readByAlphabete?.map((medicine: any) => {
          const hasImage = !!medicine.image;

          return (
            <Card key={medicine._id}>
              <Image hasImage={hasImage}>
                {hasImage ? (
                  <img
                    src={medicine.image}
                    alt={medicine.names[0]?.title || "Medicine"}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  "No Image"
                )}
              </Image>
              <Info>
                <Title>
                  <Link to={`../details/${medicine._id}`}>
                    {medicine.names[0]?.title}
                  </Link>
                </Title>
                <Price>Prix: {medicine.names[0]?.ppv || "N/A"} MAD</Price>
              </Info>
              <Status status={medicine.names[0]?.statut || "Unknown"}>
                {medicine.names[0]?.statut || "Unknown"}
              </Status>
            </Card>
          );
        })}
      </List>
    </Container>
  );
};

export default WebMedicineList;