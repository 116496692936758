import React from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import WebMedicineDetails from "./webMedicineDetails";
import { useQuery } from "@apollo/client";
import { PUBLIC_READ_MEDICINE_BY_LETTER } from "../controller/medicineController";
import WebMedicineList from "./WebMedicineList";

export const ROUTE_BASE = "medicine";

const Container = styled.div`
  text-align: left;
  font-family: Arial, sans-serif;
  padding: 20px;
`;

const AdminMedicineDash: React.FC = () => {
  
  return (
    <Container>
      <Routes>
          {/* Redirect root to a default letter, e.g., 'A' */}
          <Route path="" element={<Navigate to={`A`} />} />

          {/* Base route for the application */}
         
            {/* List by letter */}
            <Route path=":letter" element={<WebMedicineList />} />

            {/* Details view */}
            <Route path="details/:_id" element={<WebMedicineDetails />} />
    
        </Routes>
    </Container>
  );
};

export default AdminMedicineDash;
