import { gql } from "@apollo/client";

// fragments
export const MedicineNamesFragment = gql`
  fragment MedicineNames on Name {
    title presentation ppv statut distributor
  }
`
export const MedicineIdentityFragment= gql`
  fragment MedicineIdentity on Medicine {
    _id composition category atc 
    names { ...MedicineNames }
  }
  ${MedicineNamesFragment}
`

// querries
export const PUBLIC_MEDICINE_READ_READBYID = gql`
  query public_medicine_read_readById($_id: ID) {
    public_medicine_read_readById(_id: $_id) {
      ...MedicineIdentity
    }
  }
  ${MedicineIdentityFragment}
`

export const PUBLIC_READ_MEDICINE_BY_LETTER = gql`
  query public_medicine_read_readByAlphabete($letter: String!) {
    public_medicine_read_readByAlphabete(letter: $letter) {
      ...MedicineIdentity
    }
  }
  ${MedicineIdentityFragment}
`;

// mutations