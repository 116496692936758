import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as config from '../../../store/config';
import { DrugActions } from './actions';
import { AnyAction } from 'redux';



function* DrugFetchByAlphabete(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_BY_ALPHABETE_SUCCESS
    )
}
function* DrugFetchByCategory(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_CATEGORIES_SUCCESS
    )
}
function* DrugFetchByChapter(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_CHAPTERS_SUCCESS
    )
}
function* DrugFetchBySousChapter(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_SOUS_CHAPTERS_SUCCESS
    )
}
function* DrugFetchByGroup(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_GROUP_SUCCESS
    )
}
function* DrugFetchByAtc(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_BY_ATC_SUCCESS
    )
}
function* DrugFetchDetails(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_FETCH_DETAILS_SUCCESS
    )
}
function* DrugFetchByName(action: AnyAction){
    yield config.tryFetching(
        action,
        DrugActions.DRUG_LIST_BY_NAME_SUCCESS
    )
}
//watcher func dispatcher
function* watchMedicine(){

    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_BY_ALPHABETE, DrugFetchByAlphabete)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_CATEGORIES, DrugFetchByCategory)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_CHAPTERS, DrugFetchByChapter)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_SOUS_CHAPTERS, DrugFetchBySousChapter)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_GROUP, DrugFetchByGroup)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_BY_ATC, DrugFetchByAtc)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_FETCH_DETAILS, DrugFetchDetails)
    // fetch tests form server 
    yield takeEvery(DrugActions.DRUG_LIST_BY_NAME, DrugFetchByName)
}

export function* MedicineSaga(){
    yield all([fork(watchMedicine)])
}