import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PUBLIC_MEDICINE_READ_READBYID } from "../controller/medicineController";
import styled from "styled-components";

const PageContainer = styled("div")`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
`;

const Header = styled("h1")`
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
`;

const SubHeader = styled("h2")`
  font-size: 1.5rem;
  color: #555;
  margin: 10px 0;
`;

const Section = styled("div")`
  margin-bottom: 20px;
`;

const InfoList = styled("ul")`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const InfoItem = styled("li")`
  font-size: 1rem;
  color: #666;
  margin: 5px 0;

  strong {
    color: #333;
  }
`;

const PriceTag = styled("p")`
  font-size: 1.2rem;
  color: #007b00;
  font-weight: bold;
`;

const Distributor = styled("p")`
  font-size: 1rem;
  color: #0073e6;
  font-weight: bold;
`;

const WebMedicineDetails: React.FC = () => {
  const { _id } = useParams<{ _id: string }>();

  const { data, loading, error } = useQuery(PUBLIC_MEDICINE_READ_READBYID, {
    variables: { _id },
  });

  if (loading) return <PageContainer>Loading...</PageContainer>;
  if (error) return <PageContainer>Error loading medicine details.</PageContainer>;

  const medicine = data?.public_medicine_read_readById;

  if (!medicine) {
    return <PageContainer>Medicine not found.</PageContainer>;
  }

  return (
    <PageContainer>
      {/* Helmet for SEO */}
      <Helmet>
        <title>{`${medicine.names[0]?.title} - ${medicine.category} au maroc`}</title>
        <meta
          name="description"
          content={`Découvrez les détails sur ${medicine.names[0]?.title}, un médicament de la catégorie ${medicine.category} disponible au maroc. Composition : ${medicine.composition}, Prix : ${medicine.names[0]?.ppv} MAD, Présentation : ${medicine.names[0]?.presentation}, Distributeur : ${medicine.names[0]?.distributor}.`}
        />
        <meta
          name="keywords"
          content={`Médicament, ${medicine.names[0]?.title}, ${medicine.category}, ${medicine.names[0]?.country}, ${medicine.composition}, ${medicine.atc}`}
        />
        <meta property="og:title" content={`${medicine.names[0]?.title} - ${medicine.category}`} />
        <meta
          property="og:description"
          content={`Découvrez ${medicine.names[0]?.title} au ${medicine.names[0]?.country}, distribué par ${medicine.names[0]?.distributor}. Prix : ${medicine.names[0]?.ppv} MAD.`}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Page Content */}
      <Header>{medicine.names[0]?.title}</Header>
      <SubHeader>{medicine.category}</SubHeader>

      <Section>
        <h3>Details</h3>
        <InfoList>
          <InfoItem>
            <strong>Composition:</strong> {medicine.composition || "N/A"}
          </InfoItem>
          <InfoItem>
            <strong>ATC Code:</strong> {medicine.atc || "N/A"}
          </InfoItem>
          <InfoItem>
            <strong>Presentation:</strong> {medicine.names[0]?.presentation || "N/A"}
          </InfoItem>
          <InfoItem>
            <strong>Status:</strong> {medicine.names[0]?.statut || "N/A"}
          </InfoItem>
        </InfoList>
      </Section>

      <Section>
        <h3>Price</h3>
        <PriceTag>{medicine.names[0]?.ppv ? `${medicine.names[0]?.ppv} MAD` : "N/A"}</PriceTag>
      </Section>

      <Section>
        <h3>Distributor</h3>
        <Distributor>{medicine.names[0]?.distributor || "N/A"}</Distributor>
      </Section>
    </PageContainer>
  );
};

export default WebMedicineDetails;
